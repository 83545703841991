import { Car } from '@styled-icons/boxicons-regular';
import { Icon, IconProps } from './Icon';

export function DrivingIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Car />
    </Icon>
  );
}
