import { DirectionsTransit } from '@styled-icons/material-outlined';

import { Icon, IconProps } from './Icon';

export function TransitIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <DirectionsTransit />
    </Icon>
  );
}
